import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { Typography, Modal, Row, Col } from "antd"
import { WhiteSpace } from "./common"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"

const { Text } = Typography

const ProductImage = styled(Img)`
  border-radius: 4px;
  width: 100%;
  height: 200px;
`

export default function ProductsModal({
  category,
  subcategory,
  visible,
  toggle
}) {
  if (!subcategory) {
    return null
  }

  const noProducts =
    !subcategory || !subcategory.product || subcategory.product.length === 0
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 700px)"
  })

  return (
    <Modal
      visible={visible}
      title={subcategory.title}
      width={isMobileDevice ? "100%" : "80%"}
      onOk={toggle}
      onCancel={toggle}
    >
      {noProducts ? (
        <Row justify="center">
          <Text>
            Sorry but looks like there are no products for this subcategory.
          </Text>
        </Row>
      ) : (
        <Row gutter={[24, 24]}>
          {subcategory.product.map((product, index) => (
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              align="middle"
              style={{ cursor: "pointer" }}
              key={product.id}
            >
              <Link to={`/products/${category.slug}/${product.slug}`}>
                <ProductImage
                  sizes={product.images[0].sizes}
                  alt={product.images[0].title}
                />
                <WhiteSpace size={5} />
                <Text strong>{product.title}</Text>
              </Link>
            </Col>
          ))}
        </Row>
      )}
    </Modal>
  )
}
