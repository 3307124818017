import { Typography } from "antd"
import "pure-react-carousel/dist/react-carousel.es.css"
import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"

const { Paragraph } = Typography

const Card = styled.div`
  width: 97%;
  margin: 1.5%;
  border-radius: 4px;
  overflow: hidden;

  & :hover {
    transform: scale(1.01);
  }
`

const Meta = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50px;
`
export const CategoryCard = ({ category }) => (
  <Link to={`/products/${category.slug}`}>
    <Card>
      <Img sizes={category.image.sizes} alt={category.image.title} />
      <Meta>
        <Paragraph
          ellipsis={{ rows: 2, expandable: false }}
          strong
          style={{ padding: 0, margin: 0 }}
        >
          {category.title}
        </Paragraph>
      </Meta>
    </Card>
  </Link>
)
