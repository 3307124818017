import { Col, Divider, Row, Typography } from "antd"
import React from "react"
import GoogleMap from "../google-map"
import { SectionWrapper } from "./components/section"
import { A } from "../common"
import { useIntl } from "gatsby-plugin-intl"
const { Text, Title } = Typography

const GetInTouchSection = () => {
  const intl = useIntl()

  return (
    <SectionWrapper id="contactUs">
      <Title level={3}>{intl.formatMessage({ id: "getInTouch.title" })}</Title>
      <Row justify="space-between" gutter={[14, 14]}>
        <Col>
          <Row>
            <Text type="secondary">
              {intl.formatMessage({ id: "getInTouch.contact" })}
            </Text>
          </Row>
          <Row>
            <Text>+40 722 558 757</Text>
          </Row>
          <Row>
            <Text>contact@alchimia.ro</Text>
          </Row>
        </Col>
        <Col>
          <Row>
            <Text type="secondary">
              {intl.formatMessage({ id: "getInTouch.online" })}
            </Text>
          </Row>
          <Row>
            <A href="http://decoart.unasshop.ro" target="blank">
              http://decoart.unasshop.ro
            </A>
          </Row>
          <Row>
            <A href="fb.me/alchemytimisoara" target="blank">
              fb.me/alchemytimisoara
            </A>
          </Row>
        </Col>
        <Col>
          <Row>
            <Text type="secondary">
              {intl.formatMessage({ id: "getInTouch.address" })}
            </Text>
          </Row>
          <Row>
            <Text>
              9A, Hasdeu Street <br /> Timisoara, 300016 Romania
            </Text>
          </Row>
        </Col>
      </Row>
      <Divider dashed />
      <GoogleMap />
    </SectionWrapper>
  )
}

export { GetInTouchSection }
