import { Row, Typography } from "antd"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { ProductsCarousel } from "../products-carousel"
import { SectionWrapper } from "./components/section"

const { Title, Text } = Typography

const ProductionSection = () => {
  const intl = useIntl()
  return (
    <SectionWrapper id="products">
      <Row justify="space-between" align="middle" style={{ width: "100%" }}>
        <Title level={3}> {intl.formatMessage({ id: "products" })} </Title>
        <Link to="/products">
          <Text strong style={{ marginRight: 5 }}>
            {intl.formatMessage({ id: "seeAll" })}
          </Text>
        </Link>
      </Row>
      <ProductsCarousel />
    </SectionWrapper>
  )
}

export { ProductionSection }
