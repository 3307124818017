import { Button, Flex, Text } from "@chakra-ui/react"
import React from "react"
import {
  CarouselProvider,
  Dot,
  DotGroup,
  Slide,
  Slider
} from "pure-react-carousel"
import Img from "gatsby-image"
import { Typography } from "antd"
import { WhiteSpace } from "../common"
import { useWindowSize } from "../../utils/windows-size"
import { Link } from "gatsby-plugin-intl"

export const TrendingCarousel = ({ products }) => {
  const size = useWindowSize()
  const cardSize = 400
  if (products.nodes.length === 0) return null

  return (
    <>
      <WhiteSpace size={40} />
      <Typography.Title level={3}>New Products</Typography.Title>
      <CarouselProvider
        visibleSlides={Math.min(size.width / cardSize, 4)}
        totalSlides={products.nodes.length}
        step={2}
        isIntrinsicHeight
      >
        <Slider>
          {products.nodes.map((product, index) => {
            let link = ""

            if (product.subcategory) {
              link = `/products/${product.subcategory.category.slug}/${product.slug}/`
            } else if (product.category) {
              link = `/products/${product.category.slug}/${product.slug}/`
            }

            return (
              <Slide index={index} key={index}>
                <Link to={link}>
                  <Flex flexDirection="column" alignItems="center">
                    <Flex
                      width={320}
                      height={300}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Img
                        sizes={product.images[0].sizes}
                        style={{
                          objectFit: "cover",
                          margin: 2,
                          borderRadius: 4,
                          width: 300
                        }}
                      />
                    </Flex>
                    <Text size="xl" fontWeight="bold" color="gray.900">
                      {product.title}
                    </Text>
                  </Flex>
                </Link>
              </Slide>
            )
          })}
        </Slider>
        <DotGroup
          renderDots={props => {
            return (
              <Flex flexDir="row" justifyContent="center" mb={40}>
                {products.nodes.map((image, index) => (
                  <Dot slide={index}>
                    <Flex
                      mx={1}
                      bg={
                        props.currentSlide === index ? "gray.800" : "gray.300"
                      }
                      width={props.currentSlide === index ? 3 : 2}
                      height={props.currentSlide === index ? 3 : 2}
                      borderRadius={20}
                    />
                  </Dot>
                ))}
              </Flex>
            )
          }}
        />
      </CarouselProvider>
    </>
  )
}
