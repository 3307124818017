// React Component
import Burger from "@animated-burgers/burger-slip"
// don't forget the styles
import "@animated-burgers/burger-slip/dist/styles.css"

import { Menu, Tooltip } from "antd"
import { graphql, Link, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import _ from "lodash"
import React from "react"
import { animated, useSpring } from "react-spring"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import DIY from "../../assets/diy.svg"
import Logo from "../logo"
import CollapseMenu from "./collapse-menu"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import Language from "./language-switcher"

const { SubMenu } = Menu

const Navbar = props => {
  const intl = useIntl()
  const { compact = false, navbarState, toggleNavbar } = props

  const barAnimation = useSpring({
    from: { transform: "translate3d(0, -10rem, 0)" },
    transform: "translate3d(0, 0, 0)"
  })

  const { en, ro } = useStaticQuery(graphql`
    {
      en: allContentfulProductCategory(filter: { node_locale: { eq: "en" } }) {
        nodes {
          title
          slug
          description {
            id
            description
          }
          image {
            sizes(quality: 90) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
      ro: allContentfulProductCategory(filter: { node_locale: { eq: "ro" } }) {
        nodes {
          title
          slug
          description {
            id
            description
          }
          image {
            sizes(quality: 90) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  `)
  const categories = intl.locale === "en" ? en.nodes : ro.nodes

  const diyText =
    intl.locale === "en"
      ? `"Do it yourself" ("DIY") is the method of building, modifying, or repairing things without the direct aid of experts or professionals. This reflect the constant need of people for improvement. The term "do-it-yourself" has been associated with consumers since at least 1912 primarily in the domain of home improvement and maintenance activities.The phrase "do it yourself" had come into common usage (in standard English) by the 1950s, in reference to the emergence of a trend of people undertaking home improvement and various other small craft and construction projects as both a creative-recreational and cost-saving activity. ALCHEMY has considered from it early years of activity as a concept of individual freedom and progress for our customers. We have done a lot of work of research and documentation in order to provide our customers with true knowledge regarding various activities.`
      : `"Do it yourself" ("DIY") in traducere "Fa-ti Tu Singur" se regaseste in literatura de specialitate de la inceputul secolului XIX si reflecta tendinte ale consumatorilor de a realiza diverse activitati de imbunatatire in mediul familiar sau in lucrurile ce ii pasioneaza, pe cont propriu, fara ajutorul direct al specialistilor. Compania ALCHEMY, inca din primii ani de activitate a considerat acest concept o profesiune de credinta. Cercetarea continua a pietei si a inovatiilor aparute pentru a veni in intimpinarea clientilor cu cele mai bune solutii tehnologice si materiale impreuna cu cunostiintele adecvate reflecta din plin aceasta preocupare. DIY este mai mult decat a vinde o adunatura de materiale, ci inglobeaza un concept, o viziune care asigura cumparatorul ca alege in cunostiinta de cauza si va avea la dispozitie informatiile necesare si materialele auxiliare pentru a duce proiectul la bun sfarsit. Propriul magazin DecoArt, cat si platformele comerciale dar mai ales Hipermarketurile Leroy Merlin unde compania noastra listeaza materiale si solutii din categoria DIY, sunt exemple ale acestor preocupari.`

  if (
    _.isEmpty(categories) ||
    _.isUndefined(categories) ||
    _.isNull(categories)
  ) {
    return null
  }

  return (
    <>
      <NavBar style={barAnimation} open={navbarState} compact={compact}>
        <FlexContainer compact={compact}>
          {!compact && <Logo />}
          <NavLinks compact={compact}>
            <Menu mode="horizontal" style={{ background: "transparent" }}>
              <Menu.Item>
                <AnchorLink stripHash to={`/${intl.locale}/#company`}>
                  {intl.formatMessage({ id: "navbar.company" })}
                </AnchorLink>
              </Menu.Item>
              <Menu.Item>
                <AnchorLink stripHash to={`/${intl.locale}/#ourServices`}>
                  {intl.formatMessage({ id: "navbar.ourServices" })}
                </AnchorLink>
              </Menu.Item>
              <SubMenu
                title={
                  <AnchorLink stripHash to={`/${intl.locale}/#products`}>
                    <FormattedMessage id="navbar.products" />
                  </AnchorLink>
                }
                style={{ margin: 0 }}
              >
                <Menu.ItemGroup title={<FormattedMessage id="categories" />}>
                  {categories.map(category => (
                    <Menu.Item key={`category:${_.camelCase(category.title)}`}>
                      <Link to={`/products/${category.slug}`}>
                        {category.title}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              </SubMenu>
              <Menu.Item>
                <AnchorLink stripHash to={`/${intl.locale}/#distribution`}>
                  <FormattedMessage id="navbar.distribution" />
                </AnchorLink>
              </Menu.Item>
              <Menu.Item>
                <AnchorLink stripHash to={`/${intl.locale}/#contactUs`}>
                  <FormattedMessage id="navbar.contactUs" />
                </AnchorLink>
              </Menu.Item>
            </Menu>
          </NavLinks>
          {compact ? (
            <DIYCompactContainer>
              <Tooltip title={diyText}>
                <DIY />
              </Tooltip>
              <Language />
            </DIYCompactContainer>
          ) : (
            <DIYContainer>
              <Tooltip title={diyText}>
                <DIY />
              </Tooltip>
              <Language />
            </DIYContainer>
          )}

          <BurgerWrapper compact={compact}>
            <Burger
              Component="button"
              type="button"
              style={{
                color: "red",
                backgroundColor:
                  compact && navbarState ? "black" : "transparent",
                fontSize: 8
              }}
              isOpen={navbarState}
              onClick={toggleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu
        categories={categories}
        compact={compact}
        navbarState={navbarState}
        toggleNavbar={toggleNavbar}
      />
    </>
  )
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  padding-top: 10px;

  @media (max-width: ${p => (p.compact ? "769px" : "900px")}) {
    background: ${p => (p.open ? "white" : "transparent")};
  }
`

const FlexContainer = styled.div`
  max-width: 90rem;
  display: flex;
  margin: auto;
  padding: 0 1.5rem;
  justify-content: space-between;
  height: 4.5rem;

  @media (min-width: 769px) {
    justify-content: ${p => (p.compact ? "center" : "space-between")};
    align-items: center;
  }
`

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  text-transform: uppercase;
  font-family: "Algerian";

  & a {
    ${tw`z-20 px-1 m-0  cursor-pointer antialiased hover:font-semibold`};
    ${p =>
      p.compact
        ? css`
            ${tw`text-grey-lighter`}
            ${tw`hover:text-white`}
          `
        : css`
            ${tw`text-grey-darkest`}
          `}
  }

  .ant-menu-horizontal > .ant-menu-item a {
    ${tw`z-20 px-1 m-0  cursor-pointer antialiased hover:font-semibold`};
    ${p =>
      p.compact
        ? css`
            ${tw`text-grey-lighter`}
            ${tw`hover:text-white`}
          `
        : css`
            ${tw`text-grey-darkest`}
          `}
  }

  .ant-menu .ant-menu-item-selected {
    background: transparent;
  }

  .ant-menu-horizontal,
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom-width: 0px;
  }

  .ant-menu-submenu-title {
    ${tw`px-1`};
  }

  @media (max-width: ${p => (p.compact ? "769px" : "900px")}) {
    display: none;
  }
`

const BurgerWrapper = styled.div`
  margin: auto 0;

  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before {
    ${p => (p.compact ? tw`bg-white` : tw`bg-black`)};
  }

  @media (min-width: ${p => (p.compact ? "769px" : "900px")}) {
    display: none;
  }
`

const DIYContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  @media (max-width: 900px) {
    display: none;
  }
`

const DIYCompactContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 15px;
`
