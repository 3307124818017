import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Brand from "./brand"
import tw from "twin.macro"
import { Button, Link } from "@chakra-ui/react"
import { WhiteSpace } from "./common"

const AbsoluteWrapper = styled.div`
  ${tw`-mt-48`}
  position: relative;
  min-height: 800px;
  height: 100vh;

  @media (min-width: 768px) {
    height: 85vh;
  }
`

const Overlay = styled.div`
  ${tw`absolute h-full inset-0 z-10 flex justify-center items-center`}
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
`

const BrandWrapper = styled.div`
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    margin-top: 20%;
  }
`

const Image = styled(Img)`
  ${tw`h-full object-fill`}
`

const HeroImage = ({ image, intl }) => {
  const btnLabel = intl.locale === "en" ? "Online Store" : "Magazin Online"

  return (
    <AbsoluteWrapper>
      <Overlay>
        <BrandWrapper>
          <Brand />
          <WhiteSpace size={20} />
          <Button>
            <Link
              href="http://decoart.unasshop.ro"
              isExternal
              style={{ color: "black" }}
            >
              {btnLabel}
            </Link>
          </Button>
        </BrandWrapper>
      </Overlay>
      <Image sizes={image.sizes} alt={image.title} />
    </AbsoluteWrapper>
  )
}

export default HeroImage
