import { Typography } from "antd"
import _ from "lodash"
import React from "react"
import { Services } from "../service"
import { SectionWrapper } from "./components/section"
import { useIntl } from "gatsby-plugin-intl"

const { Title } = Typography

const OurServiceSection = ({ services }) => {
  const intl = useIntl()
  if (_.isEmpty(services) || _.isUndefined(services) || _.isNull(services)) {
    return
  }

  return (
    <SectionWrapper id="ourServices" withAccent>
      <Title level={3}>
        {intl.formatMessage({ id: "homePage.ourServices" })}
      </Title>
      <Services services={services} />
    </SectionWrapper>
  )
}

export { OurServiceSection }
