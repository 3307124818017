import styled from "styled-components"
import tw from "twin.macro"

export const MaxWidth = styled.div`
  max-width: 90rem;
  margin: auto;
  padding: 1.5rem 1.5rem;
`

export const WhiteSpace = styled.div`
  height: ${p => p.size}px;
  width: ${p => p.size}px;
`

export const A = tw.a`text-blue-dark hover:text-blue-light`
