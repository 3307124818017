import React from "react"
import { Typography, Row } from "antd"
import { A } from "../common"

const { Text } = Typography

const ProductDocuments = ({ documents }) => {
  if (!documents || documents.length === 0) return null

  return (
    <>
      <Row>
        <Text strong>Documents</Text>
      </Row>
      {documents.map(document => (
        <Row>
          <A href={`https:${document.file.url}`} target="blank">
            {document.title}
          </A>
        </Row>
      ))}
    </>
  )
}

export { ProductDocuments }
