import React from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"

import { MaxWidth } from "../.."

export const SectionWrapperComponent = styled.section`
  ${tw`px-16`};
  ${p =>
    p.withAccent &&
    css`
      ${tw`bg-white`}
    `}

  padding-top: 5vh;
  padding-bottom: 5vh;

  @media (max-width: 640px) {
    ${tw`px-0 py-8`};
  }
`

const FlexWrap = styled.div`
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }
`

export const SectionTitle = tw.h1`text-4xl py-8 font-black`
export const SectionText = tw.p`text-lg text-black font-medium`

export const SectionWrapper = ({ withAccent, children, id }) => (
  <SectionWrapperComponent withAccent={withAccent} id={id}>
    <MaxWidth>
      <FlexWrap>{children}</FlexWrap>
    </MaxWidth>
  </SectionWrapperComponent>
)
