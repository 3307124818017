import { Menu, Row } from "antd"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import React from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"
import tw from "twin.macro"
import DIY from "../../assets/diy.svg"
import { WhiteSpace } from "../common"
import Language from "./language-switcher"
import { Link } from "gatsby"
import _ from "lodash"
import { useMediaQuery } from "react-responsive"

const { SubMenu } = Menu

const CollapseMenu = props => {
  const intl = useIntl()

  const { open } = useSpring({ open: props.navbarState ? 0 : 1 })
  const { compact = false, categories } = props

  const minWidth = compact ? "769px" : "900px"
  const isLargeScreen = useMediaQuery({
    query: `(min-width: ${minWidth})`
  })

  if (isLargeScreen) {
    return null
  }

  if (props.navbarState === true) {
    return (
      <CollapseWrapper
        style={{
          zIndex: 100,
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200]
            })
            .interpolate(openValue => `translate3d(0, ${openValue}px, 0`)
        }}
      >
        <NavLinks compact={compact}>
          <Menu mode="inline">
            <Menu.Item onClick={props.toggleNavbar}>
              <AnchorLink stripHash to={`/${intl.locale}/#company`}>
                {intl.formatMessage({ id: "navbar.company" })}
              </AnchorLink>
            </Menu.Item>
            <Menu.Item onClick={props.toggleNavbar}>
              <AnchorLink stripHash to={`/${intl.locale}/#ourServices`}>
                {intl.formatMessage({ id: "navbar.ourServices" })}
              </AnchorLink>
            </Menu.Item>
            <SubMenu
              title={
                <div onClick={props.toggleNavbar}>
                  <AnchorLink stripHash to={`/${intl.locale}/#products`}>
                    <FormattedMessage id="navbar.products" />
                  </AnchorLink>
                </div>
              }
              style={{ margin: 0 }}
            >
              <Menu.ItemGroup title={<FormattedMessage id="categories" />}>
                {categories.map(category => (
                  <Menu.Item key={`category:${_.camelCase(category.title)}`}>
                    <Link to={`/products/${category.slug}`}>
                      {category.title}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </SubMenu>
            <Menu.Item onClick={props.toggleNavbar}>
              <AnchorLink stripHash to={`/${intl.locale}/#distribution`}>
                <FormattedMessage id="navbar.distribution" />
              </AnchorLink>
            </Menu.Item>
            <Menu.Item onClick={props.toggleNavbar}>
              <AnchorLink stripHash to={`/${intl.locale}/#contactUs`}>
                <FormattedMessage id="navbar.contactUs" />
              </AnchorLink>
            </Menu.Item>
          </Menu>
          <Row align="middle" style={{ marginLeft: "1.25rem" }}>
            {!compact && (
              <>
                <DIY />
                <WhiteSpace size={25} />
              </>
            )}
            <Language />
          </Row>
        </NavLinks>
      </CollapseWrapper>
    )
  }
  return null
}

export default CollapseMenu

const CollapseWrapper = styled(animated.div)`
  background: white;
  position: fixed;
  top: 5rem;
  left: 0;
  right: 0;
`

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 1rem;

  & li {
    transition: all 300ms linear 0s;
  }

  & a {
    font-size: 1.4rem;
    line-height: 2;
    color: #2d3436;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #fdcb6e;
      border-bottom: 1px solid #fdcb6e;
    }
  }

  .ant-menu-item,
  .ant-menu-item-group-title,
  .ant-menu-item > a,
  .ant-menu-item > span > a {
    ${tw`hover:font-medium`};
  }
`
