import React, { useEffect, useState } from "react"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { Button, Typography, Row } from "antd"
import { useMediaQuery } from "react-responsive"
import { useIntl } from "gatsby-plugin-intl"

import _ from "lodash"

import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons"
import { graphql, useStaticQuery } from "gatsby"
import { CategoryCard } from "./cards"
import { Span } from "./typography"

const { Text } = Typography

const ProductsCarousel = () => {
  const intl = useIntl()
  const { en, ro } = useStaticQuery(graphql`
    {
      en: allContentfulProductCategory(filter: { node_locale: { eq: "en" } }) {
        nodes {
          slug
          title
          description {
            id
            description
          }
          image {
            sizes(quality: 90) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
      ro: allContentfulProductCategory(filter: { node_locale: { eq: "ro" } }) {
        nodes {
          slug
          title
          description {
            id
            description
          }
          image {
            sizes(quality: 90) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  `)

  const [visibleSlides, setVisibleSlides] = useState(3)
  const isXLScreen = useMediaQuery({
    query: "(min-width: 1200px)"
  })

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 900px)"
  })

  const isMobileDevice = useMediaQuery({
    query: "(max-width: 500px)"
  })

  const categories = intl.locale === "en" ? en.nodes : ro.nodes

  useEffect(() => {
    const visibleSlides = isXLScreen
      ? 4
      : isLargeScreen
      ? 3
      : isMobileDevice
      ? 1
      : 2

    setVisibleSlides(visibleSlides)
  }, [isLargeScreen, isMobileDevice, isXLScreen])

  if (
    _.isEmpty(categories) ||
    _.isUndefined(categories) ||
    _.isNull(categories)
  ) {
    return
  }

  return (
    <CarouselProvider
      visibleSlides={visibleSlides}
      totalSlides={categories.length}
      step={1}
      naturalSlideWidth={220}
      naturalSlideHeight={500}
      style={{ width: "100%" }}
    >
      <Row
        justify="space-between"
        align="middle"
        style={{
          width: "100%",
          paddingRight: 5,
          paddingBottom: 20
        }}
      >
        <Text strong style={{ marginBottom: 0, fontSize: 22 }}>
          {intl.formatMessage({ id: "categories" })}
          <Span>{` (${categories.length})`}</Span>
        </Text>
        <Row>
          <ButtonBack>
            <Button shape="circle">
              <div style={{ marginTop: -10, marginLeft: -2 }}>
                <CaretLeftOutlined />
              </div>
            </Button>
          </ButtonBack>
          <div style={{ width: 10 }} />
          <ButtonNext>
            <Button shape="circle">
              <div style={{ marginTop: -10, marginRight: -2 }}>
                <CaretRightOutlined />
              </div>
            </Button>
          </ButtonNext>
        </Row>
      </Row>
      <Slider>
        {categories.map((category, index) => (
          <Slide index={index} key={index}>
            <CategoryCard category={category} />
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  )
}

export { ProductsCarousel }
