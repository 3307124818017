import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import Ro from "../../assets/romania.svg"
import Gb from "../../assets/united-kingdom.svg"

const languageName = {
  en: <Gb style={{height: 25}}/>,
  ro: <Ro style={{height: 25}}/>
}

const Language = () => (
  <div style={{display: "flex", flexDirection: "row"}}>

    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => (
          <a
            key={language}
            onClick={() => changeLocale(language)}
            style={{
              margin: 4,
              cursor: `pointer`,
              opacity: currentLocale === language ? 1 : 0.6
            }}
          >
            {languageName[language]}
          </a>
        ))
      }
      
    </IntlContextConsumer>
  </div>
)

export default Language
