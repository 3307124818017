import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import tw from "twin.macro"
import BrandLogo from "../assets/brand-logo.svg"

const LinkComponent = styled(Link)`
  ${tw`flex flex-col justify-center items-center `}
  text-decoration: none;
`

const Brand = () => (
  <LinkComponent to="/">
    <BrandLogo />
  </LinkComponent>
)

export default Brand
