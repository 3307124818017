import { Typography } from "antd"
import _ from "lodash"
import React from "react"
import { SectionWrapper } from "./components/section"
import { useIntl } from "gatsby-plugin-intl"
const { Text, Title } = Typography

const CompanySection = ({ theCompany }) => {
  const intl = useIntl()
  if (
    _.isEmpty(theCompany) ||
    _.isUndefined(theCompany) ||
    _.isNull(theCompany)
  ) {
    return
  }

  return (
    <SectionWrapper id="company">
      <Title level={3}>
        {intl.formatMessage({ id: "homePage.theCompany" })}
      </Title>
      <Text>{theCompany.theCompany}</Text>
    </SectionWrapper>
  )
}

export { CompanySection }
