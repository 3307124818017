import React from "react"
import { Layout, Row, Col } from "antd"
import dayjs from "dayjs"
import { MaxWidth } from "./common"
import styled from "styled-components"
import tw from "twin.macro"

export const Wrapper = styled.div`
  ${tw`px-16 py-2`};

  @media (max-width: 640px) {
    ${tw`px-0 py-1`};
  }
`

export const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: "center", padding: 0 }}>
      <Wrapper>
        <MaxWidth>
          <Row justify="end" style={{ marginRight: 5 }}>
            <Col align="end">
              <p style={{ fontFamily: "Algerian", marginBottom: 0 }}>
                Alchemy SRL
              </p>
              <span>©2005 - {dayjs().year()}</span>
            </Col>
          </Row>
        </MaxWidth>
      </Wrapper>
    </Layout.Footer>
  )
}
