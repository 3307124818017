import React, { useState } from "react"
import styled from "styled-components"
import { WhiteSpace } from "../common"
import { Row, Divider, Typography, Col } from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import ProductsModal from "../products-modal"
import Img from "gatsby-image"
const { Text } = Typography

const FlexWrap = styled.div`
  padding: 0 2rem;
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }
`

const SubCategoryImage = styled(Img)`
  width: 34vw;
  height: 25vw;
  border-radius: 4px;
  max-width: 350px;
  max-height: 225px;

  @media (max-width: 900px) {
    width: 90vw;
    max-width: 90vw;
    height: 50vw;
    max-height: 50vw;
  }
`

const SubcategorySection = injectIntl(({ category, subcategories, intl }) => {
  const [visible, setVisible] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  if (!subcategories.nodes || subcategories.nodes.length === 0) {
    return null
  }

  return (
    <>
      <FlexWrap>
        <WhiteSpace size={20} />
        <Row justify="center">
          <Divider dashed />
          <Text strong>{intl.formatMessage({ id: "subcategories" })}</Text>
          <Divider dashed />
        </Row>

        <WhiteSpace size={10} />
        <Row gutter={[12, 24]} justify="center">
          {subcategories.nodes.map((subcategory, index) => (
            <Col
              align="middle"
              style={{ cursor: "pointer" }}
              key={subcategory.id}
              onClick={() => {
                setActiveIndex(index)
                setVisible(true)
              }}
            >
              <SubCategoryImage
                sizes={subcategory.image.sizes}
                alt={subcategory.image.title}
              />
              <WhiteSpace size={2.5} />
              <Text>{subcategory.title}</Text>
            </Col>
          ))}
        </Row>
      </FlexWrap>
      <ProductsModal
        visible={visible}
        category={category}
        subcategory={subcategories.nodes[activeIndex]}
        toggle={() => setVisible(false)}
      />
    </>
  )
})

export { SubcategorySection }
