import { Row } from "antd"
import Img from "gatsby-image"
import {
  CarouselProvider,
  Dot,
  DotGroup,
  Slide,
  Slider
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React from "react"
import ProductThumbnail from "./product-thumbnail"

const ProductImages = ({ images }) => {
  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={images.length}
      step={1}
      naturalSlideWidth={220}
      naturalSlideHeight={175}
      // hasMasterSpinner
    >
      <Slider>
        {images.map((image, index) => (
          <Slide index={index} key={index}>
            <Img
              sizes={image.sizes}
              style={{ objectFit: "cover", margin: 2, borderRadius: 4 }}
            />
          </Slide>
        ))}
      </Slider>
      <DotGroup
        showAsSelectedForCurrentSlideOnly
        dotNumbers
        renderDots={props => {
          return (
            <Row>
              {images.map((image, index) => (
                <Dot slide={index}>
                  <ProductThumbnail
                    image={image}
                    key={image.id}
                    active={props.currentSlide === index}
                  />
                </Dot>
              ))}
            </Row>
          )
        }}
      />
    </CarouselProvider>
  )
}

export default ProductImages
