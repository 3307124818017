import React from "react"
import styled from "styled-components"
import { WhiteSpace } from "../common"
import { Row, Divider, Typography, Col } from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { Link } from "gatsby"
const { Text } = Typography

const FlexWrap = styled.div`
  padding: 0 2rem;
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }
`

const CategoryProductImage = styled(Img)`
  width: 34vw;
  height: 25vw;
  border-radius: 4px;
  max-width: 350px;
  max-height: 225px;

  @media (max-width: 900px) {
    width: 90vw;
    max-width: 90vw;
    height: 50vw;
    max-height: 50vw;
  }
`

const CategoryProductsSection = injectIntl(({ category, products, intl }) => {
  if (!products.nodes || products.nodes.length === 0) {
    return null
  }

  return (
    <>
      <FlexWrap>
        <WhiteSpace size={20} />
        <Row justify="center">
          <Divider dashed />
          <Text strong>{intl.formatMessage({ id: "products" })}</Text>
          <Divider dashed />
        </Row>

        <WhiteSpace size={10} />
        <Row gutter={[12, 24]} justify="center">
          {products.nodes.map((product, index) => (
            <Col align="middle" style={{ cursor: "pointer" }} key={product.id}>
              <Link to={`/products/${category.slug}/${product.slug}`}>
                <CategoryProductImage
                  sizes={product.images[0].sizes}
                  alt={product.title}
                />
                <WhiteSpace size={4} />
                <Text>{product.title}</Text>
              </Link>
            </Col>
          ))}
        </Row>
      </FlexWrap>
    </>
  )
})

export { CategoryProductsSection }
