import _ from "lodash"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled, { createGlobalStyle } from "styled-components"

import tw from "twin.macro"
import HeroImage from "./hero-image"
import { Footer } from "./"
import Navbar from "./navbar/navbar"
import "../theme/global.css"

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  body {
    color: ${props => (props.theme === "purple" ? "purple" : "white")};
  }

  .ant-menu-item-selected,
  .ant-menu-item-selected > a,
  .ant-menu-item .ant-menu-item-only-child .ant-menu-item-selected {
    color: inherit;
  }

  .ant-menu-item-active {
    color: #22292f;
    font-weight: 600;
  }
  .ant-menu-item-group-list {
    width: 350px;
  }
`

const LayoutComp = styled.div`
  ${tw`w-full overflow-hidden bg-grey-lightest`}
`

const Content = styled.div`
  ${tw`mx-auto`}
  min-height: 100vh;
`

const Container = ({ defKey, children, brandImage, intl }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen)
  }

  return (
    <LayoutComp>
      <GlobalStyle />
      <Navbar
        navbarState={navbarOpen}
        toggleNavbar={toggleNavbar}
        compact={!_.isEmpty(brandImage)}
      />
      {brandImage && <HeroImage image={brandImage} intl={intl} />}
      <Content>{children}</Content>
      <Footer />
    </LayoutComp>
  )
}
Container.propTypes = {
  children: PropTypes.node.isRequired
}

export default Container
