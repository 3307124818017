import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import tw from "twin.macro"

export const ProductCharacteristics = styled(ReactMarkdown)`
  ${tw`text-grey-darkest leading-snug w-full`}

  tbody {
    border-radius: 4;
  }

  tr td:first-child {
    width: 200px;
    font-weight: 600;
    text-align: center;
    background: #f0f4f9;
    ${tw`text-grey-darkest `};
  }

  td {
    border: solid 1px #ecedf1;
    ${tw`text-grey-darkest px-5`}
  }

  th {
    border-bottom-width: 0;
  }
`
