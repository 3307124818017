import React from "react"

const Image = ({ image }) => {
  const {
    file: { url },
    title
  } = image
  return <img src={url} alt={title} style={{ width: "12rem", maxHeight: "8rem" }} />
}

export default Image
