import { Divider, Row, Typography } from "antd"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import _ from "lodash"
import React from "react"
import Image from "../image"
import { SectionWrapper } from "./components/section"
const { Text, Title } = Typography

const DistributionSection = () => {
  const intl = useIntl()
  const { en, ro } = useStaticQuery(graphql`
    {
      en: contentfulHomePage(node_locale: { eq: "en" }) {
        id
        distribution {
          distribution
        }
        distributionFirms {
          id
          title
          file {
            url
            fileName
            contentType
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      ro: contentfulHomePage(node_locale: { eq: "ro" }) {
        id
        distribution {
          distribution
        }
        distributionFirms {
          id
          title
          file {
            url
            fileName
            contentType
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  `)

  const data = intl.locale === "en" ? en : ro

  if (_.isEmpty(data) || _.isUndefined(data) || _.isNull(data)) {
    return
  }

  return (
    <SectionWrapper id="distribution" withAccent>
      <Title level={3}>
        {intl.formatMessage({ id: "homePage.distribution" })}
      </Title>
      <Text>{data.distribution.distribution}</Text>
      <Divider dashed />
      <Row justify="center">
        {data.distributionFirms.map(img => (
          <Image image={img} key={img.title} />
        ))}
      </Row>
    </SectionWrapper>
  )
}

export { DistributionSection }
