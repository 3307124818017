// import React, { useState } from "react"
// import GoogleMapReact from "google-map-react"
import GoogleMap from "google-map-react"
import React, { Component } from "react"
import shouldPureComponentUpdate from "react-pure-render/function"
import { Tooltip } from "antd"

import styled from "styled-components"
import tw from "twin.macro"
import PinSvg from "../assets/pin.svg"

const MapWrapper = styled.div`
  ${tw`w-full`};
  height: 40vh;
`

export default class SimpleMapPage extends Component {
  static defaultProps = {
    center: [45.754492, 21.209344],
    zoom: 14,
    greatPlaceCoords: { lat: 45.754492, lng: 21.209344 }
  }

  shouldComponentUpdate = shouldPureComponentUpdate

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <MapWrapper>
        <GoogleMap
          bootstrapURLKeys={{ key: "AIzaSyA9nrOa-Kt7rNxca_441EnwGKXWnurHKE8" }}
          center={this.props.center}
          zoom={this.props.zoom}
        >
          <Pin
            {...this.props.greatPlaceCoords}
            text={
              "9A, Hasdeu Street Timisoara, 300016 Romania"
            } /* road circle */
          />
        </GoogleMap>
      </MapWrapper>
    )
  }
}

class Pin extends Component {
  shouldComponentUpdate = shouldPureComponentUpdate

  render() {
    return (
      <div
        style={{
          marginTop: -30,
          marginLeft: -15
        }}
      >
        <Tooltip
          placement="top"
          title={"9A, Hasdeu Street Timisoara, 300016 Romania"}
        >
          <PinSvg fill="#ef5753" width={30} height={30} />
        </Tooltip>
        {/* <MarkerText>{this.props.text}</MarkerText> */}
      </div>
    )
  }
}
