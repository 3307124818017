import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import tw from "twin.macro"

const LogoText = styled.p`
  ${tw`z-20 text-black m-0 antialiased text-4xl`};
  font-family: "Algerian";
`

const LinkComponent = styled(Link)`
  ${tw`flex flex-col justify-center items-center `}
  text-decoration: none;
`

const Logo = () => (
  <LinkComponent to="/">
    <LogoText>Alchemy</LogoText>
  </LinkComponent>
)

export default Logo
