import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import tw from "twin.macro"

export const ProductTagline = styled(ReactMarkdown)`
  ${tw`text-grey-darkest leading-snug w-full`}

  th, td {
    text-align: center;
    font-weight: 600;
  }

  td {
    ${tw`text-grey-darkest`}
  }

  th {
    color: rgba(0, 0, 0, 0.65);
    padding-bottom: 0;
    border-bottom-width: 0;
    border-top-width: 1px;
    border-top-style: solid;
  }
`
