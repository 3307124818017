import { Row } from "antd"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Image from "./image"

const Wrapper = styled.div`
  ${tw`flex p-2 py-4`};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 900px) {
    flex-direction: row;
    width: 50%;
  }
`

const SectionWrapper = styled.div`
  ${tw`flex p-2`};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;

  @media (min-width: 900px) {
    width: 65%;
  }
`

const ServiceTitle = tw.h1`text-lg font-bold text-grey-darkest`
const ServiceText = tw.h1`text-base font-medium text-grey-darker`

export const Service = ({ service }) => (
  <Wrapper>
    <Image image={service.image} />
    <SectionWrapper>
      <ServiceTitle>{service.title}</ServiceTitle>
      <ServiceText>{service.description.description}</ServiceText>
    </SectionWrapper>
  </Wrapper>
)

export const Services = ({ services }) => (
  <Row gutter={[26, 16]} justify="space-between">
    {services.map((service, index) => (
      <Service service={service} key={index} />
    ))}
  </Row>
)
