import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import tw from "twin.macro"

const Thumbnail = styled(Img)`
  height: 3.5rem;
  width: 5rem;
  ${tw`rounded z-20`};
`

const Overlay = styled.div`
  ${tw`absolute inset-0 bg-black bg-opacity-50 z-30 rounded-sm`}
`

const Wrapper = tw.div`relative pr-2 pt-2 rounded-sm overflow-hidden cursor-pointer`

const ProductThumbnail = ({ image, active }) => {
  return (
    <Wrapper>
      <div style={{ position: "relative" }}>
        {!active && <Overlay />}
        <Thumbnail sizes={image.sizes} />
      </div>
    </Wrapper>
  )
}

export default ProductThumbnail
